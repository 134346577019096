.lmiw-c-swiper{
    width: 100%;
    height: 100%;
}
.lmiw-c-swiper--main{
    background-color:rgba(0, 0, 0, 0.05);
}
/* .lmiw-c-swiper .swiper-slide{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
} */

.lmiw-c-swiper .__video{
    display: flex;
    align-items: center;
}

.lmiw-c-swiper .__image{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.lmiw-c-swiper .__add{
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #088de2;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    border-radius: 7px;
}

  
.lmiw-c-swiper .swiper-wrapper {
    box-sizing: border-box;
}

.lmiw-c-swiper--main{
    border-radius: 7px;
    border: 1px solid #cdcd;
}

.lmiw-c-swiper--main .swiper-button-next, 
.lmiw-c-swiper--main .swiper-button-prev{
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50px;
    width: 60px;
    height: 60px;
    color: white; 
    margin: 0 25px;
}


.lmiw-c-swiper--main .swiper-button-next::after, 
.lmiw-c-swiper--main .swiper-button-prev::after{
    font-size: 24px;
}

.lmiw-c-swiper--thumbnail{
    box-sizing: border-box;
    padding: 10px 0;
}

.lmiw-c-swiper--thumbnail .swiper-slide {
    opacity: 0.6;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: hidden;
  }

.lmiw-c-swiper--thumbnail .swiper-slide-thumb-active {
    opacity: 1;
    border: 2px solid #038de2;
  }

